import { Link } from "react-router-dom"

const ButtonLink = ({ color, text, link,  }) => {
    return (
        <>
           <Link to={link} className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-800 sm:px-8">
            { text }
            </Link>
        </>
    )
}

export default ButtonLink