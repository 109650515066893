import { CoverImg } from "../constants/images";
import { CTA } from "../container";
import { useTranslation } from "react-i18next";

const Service = ({ service }) => {
  const [t, i18n] = useTranslation();

  return (
    <>
      <div className="bg-white">
        <div aria-hidden="true" className="relative">
          <img
            src={CoverImg}
            alt=""
            className="w-full h-96 object-center object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-white" />
        </div>

        <div className="relative -mt-12 max-w-7xl mx-auto pb-16 px-4 sm:pb-24 sm:px-6 lg:px-8">
          <div className="max-w-3xl">
            <p className="font-medium uppercase text-gray-900">
              {t("Our services")}
            </p>
            <h2 className="mt-2 text-3xl font-extrabold tracking-tight text-primary-900 sm:text-4xl">
              {service.title}
            </h2>
            <p className="mt-2 text-sm font-semibold text-gray-900 italic">
              {service.subtitle}
            </p>
            <p className="mt-4 text-gray-500">{service.description}</p>
          </div>

          <div className="mt-20">
            <service.content />
          </div>
        </div>
      </div>
      <CTA />
    </>
  );
};

export default Service;
