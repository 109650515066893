import { Fragment, useRef, useState } from "react";
import { contacts as contactsData } from "../data";
import { Input } from "../components";
import { PaperAirplaneIcon, XIcon } from "@heroicons/react/solid";
import emailjs from "@emailjs/browser";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { app } from "../data";

const Contact = () => {
  const form = useRef();
  const [showNotif, setShowNotif] = useState(false);
  const [t, i18n] = useTranslation();

  const contacts = contactsData.map((contact) => {
    if (i18n.language === "fr") {
      return {
        title: contact.title_fr,
        icon: contact.icon,
        text: contact.text_fr,
      };
    }
    return contact;
  });

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "contact_service",
        "contact_template",
        form.current,
        "6dpkk9-Zon6qMrTEI"
      )
      .then(
        (result) => {
          console.log(result.text);
          setShowNotif(true);
          setTimeout(() => {
            setShowNotif(false);
          }, 5000);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div className="relative bg-gray-50">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-800" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-800 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-extrabold tracking-tight text-primary-900 sm:text-3xl">
              {t("Contact us")}
            </h2>
            <p className="mt-8 text-lg leading-6 text-white">{app.shortName}</p>
            <dl className="mt-3 text-base text-gray-300 space-y-3">
              {contacts.map((contact, key) => (
                <div key={"contact-" + key}>
                  <dt className="sr-only">{contact.name}</dt>
                  <dd className="flex">
                    <contact.icon
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">{contact.text}</span>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <form
              ref={form}
              onSubmit={sendEmail}
              className="grid grid-cols-1 gap-y-6"
            >
              <Input name="name" type="text" label={t("Name")} required />
              <Input name="email" type="email" label={t("Email")} required />
              <Input name="phone" type="tel" label={t("Phone")} required />
              <div>
                <label htmlFor="message" className="sr-only">
                  {t("Message")}
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="block w-full text-sm py-2 px-3 placeholder-gray-400 focus:ring-primary-900 focus:border-primary-900 border border-gray-200 rounded-md"
                  placeholder={t("Message")}
                  required
                  defaultValue={""}
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="inline-flex items-center uppercase px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-900 hover:bg-primary-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-900"
                >
                  {t("Send")}
                  <PaperAirplaneIcon
                    className="ml-2 -mr-1 h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={showNotif}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      {t("Sent successfully")}!
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      {t("Your message has been sent successfully")}
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => {
                        setShowNotif(false);
                      }}
                    >
                      <span className="sr-only">{t("Close")}</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  );
};

export default Contact;
