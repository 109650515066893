import {
  StationRabatVilleImg,
  TowerBCPImg,
  TowerM6Img,
} from "../constants/images";

const posts = [
  {
    id: 1,
    title: "Tour M6",
    description: "70 000m2 de flocage et 14 000m2 de chape en pente.",
    imageUrl: TowerM6Img,
  },
  {
    id: 1,
    title: "Tour BCP",
    description: "4000m2 de flocage produit fibreux.",
    imageUrl: TowerBCPImg,
  },
  {
    id: 1,
    title: "Gare de Rabat ville",
    description: "",
    imageUrl: StationRabatVilleImg,
  },
  // More posts...
];

export default function TrustUs() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Ils nous ont fait confiance
          </h2>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article key={post.id} className="flex flex-col items-start">
              <div className="relative w-full">
                <img
                  alt=""
                  src={post.imageUrl}
                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">
                <div className="group relative">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    {post.title}
                  </h3>
                  <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                    {post.description}
                  </p>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
}
