import { Hero, About, Services, Partners, Contact } from "../container";
import TrustUs from "../container/TrustUs";

const Home = () => {
  return (
    <>
      <Hero />
      <About />
      <Services />
      <TrustUs />
      <Partners />
      <Contact />
    </>
  );
};

export default Home;
