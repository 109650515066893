import {
  FlocageImg,
  FireSvg,
  SnowSvg,
  SoundSvg,
  DropSvg,
} from "../constants/images";
import { flocage as flocageData } from "../data";
import { useTranslation } from "react-i18next";

const Flocage = () => {
  const [t, i18n] = useTranslation();

  const flocage =
    i18n.language === "fr"
      ? {
          title: flocageData.title_fr,
          subtitle: flocageData.subtitle_fr,
          description: flocageData.description_fr,
          details: flocageData.details_fr,
        }
      : flocageData;

  return (
    <div className="relative max-w-7xl mx-auto bg-gray-800 2xl:rounded-lg 2xl:shadow-2xl">
      <div className="h-56 bg-green-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2 2xl:rounded-l-lg">
        <img
          className="w-full h-full object-cover 2xl:rounded-l-lg"
          src={FlocageImg}
          alt=""
        />
      </div>
      <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <h2 className="text-2xl font-bold uppercase tracking-wider text-primary-900">
            {flocage.title}
          </h2>
          <p className="mt-2 text-white text-lg font-bold">
            {flocage.subtitle}
          </p>
          <p className="mt-3 text-lg text-gray-300">{flocage.description}</p>
          <div className="mt-6">
            <flocage.details />
            <div className="mt-8 grid grid-cols-4 gap-4 max-w-sm">
              <div>
                <img src={FireSvg} className="w-16 h-16" alt="" />
              </div>
              <div>
                <img src={SnowSvg} className="w-16 h-16" alt="" />
              </div>
              <div>
                <img src={SoundSvg} className="w-16 h-16" alt="" />
              </div>
              <div>
                <img src={DropSvg} className="w-16 h-16" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Flocage;
