import { AboutImg } from "../constants/images";
import { useTranslation } from "react-i18next";
import { aboutUs as aboutUsData } from "../data";
import { BadgeCheckIcon, EyeIcon } from "@heroicons/react/outline";

const About = () => {
  const [t, i18n] = useTranslation();

  const aboutUs =
    i18n.language === "fr" ? { text: aboutUsData.text_fr } : aboutUsData;

  return (
    <div className="relative py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="">
          <h2 className="mt-2 text-3xl font-extrabold text-primary-900 tracking-tight sm:text-4xl">
            {t("About us")}
          </h2>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2 lg:pl-12">
            <img
              src={AboutImg}
              alt=""
              className="w-full lg:h-full h-60 object-cover bg-gray-100 rounded-lg"
            />
          </div>
          <div className="space-y-4 mt-6 lg:mt-0 text-justify text-gray-500">
            <aboutUs.text />
          </div>
        </div>

        <dl className="mt-24 grid grid-cols-1 gap-x-8 gap-y-10  lg:grid-cols-2 lg:gap-y-16">
          <div className="relative pl-16">
            <dt className="text-base font-semibold leading-7 text-gray-900">
              <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-primary-600">
                <BadgeCheckIcon
                  aria-hidden="true"
                  className="h-6 w-6 text-white"
                />
              </div>
              Mission :
            </dt>
            <dd className="mt-2 text-base leading-7 text-gray-600">
              {
                {
                  fr: "Être le premier fournisseur de solutions de construction complètes en Afrique, en offrant un service, une qualité et une innovation exceptionnels tout en respectant la responsabilité environnementale.",
                  en: "To be the premier provider of comprehensive construction solutions in Africa, delivering exceptional service, quality, and innovation while upholding environmental responsibility.",
                }[i18n.language || "fr"]
              }
            </dd>
          </div>

          <div className="relative pl-16">
            <dt className="text-base font-semibold leading-7 text-gray-900">
              <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-primary-600">
                <EyeIcon aria-hidden="true" className="h-6 w-6 text-white" />
              </div>
              Vision :
            </dt>
            <dd className="mt-2 text-base leading-7 text-gray-600">
              {
                {
                  fr: "Transformer l'industrie de la construction grâce à notre engagement indéfectible envers l'excellence, en créant des structures durables et résilientes qui améliorent la vie de nos communautés",
                  en: "To transform the construction industry through our unwavering commitment to excellence, creating sustainable and resilient structures that enhance the lives of our communities.",
                }[i18n.language || "fr"]
              }
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default About;
