import { Home, Service } from "./Pages";
import { Link, Routes, Route, Outlet } from "react-router-dom";
import { Footer, Navbar, About, Contact } from "./container";
import { ScrollToTop } from "./components";
import { services } from "./data";

const App = () => {
  return (
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          {services.map((service) => (
            <Route
              key={"route-" + service.slug}
              path={service.slug}
              element={<Service service={service} />}
            />
          ))}
          <Route path="contact" element={<Contact />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ScrollToTop>
  );
};

export default App;

function Layout() {
  return (
    <div>
      <header>
        <Navbar />
      </header>

      <main>
        <Outlet />
      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  );
}

function NotFound() {
  return (
    <div>
      <h2>404 - Page Not Found!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
