import {
  app as appData,
  services as servicesData,
  contacts as contactsData,
} from "../data";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [t, i18n] = useTranslation();

  const app =
    i18n.language === "fr"
      ? { name: appData.name_fr, logo: appData.logo }
      : appData;

  const services = servicesData.map((service) => {
    if (i18n.language === "fr") {
      return {
        route: service.route,
        title: service.title_fr,
      };
    }
    return service;
  });

  const contacts = contactsData.map((contact) => {
    if (i18n.language === "fr") {
      return {
        title: contact.title_fr,
        icon: contact.icon,
        text: contact.text_fr,
      };
    }
    return contact;
  });

  return (
    <footer
      className="bg-white border-t border-gray-200"
      aria-labelledby="footer-heading"
    >
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-2 xl:gap-8">
          <div className="space-y-4">
            <Link to="/">
              <img className="h-12" src={app.logo} alt={app.name} />
            </Link>
            <h3 className="text-sm font-semibold text-gray-500 tracking-wider uppercase">
              {app.name}
            </h3>
            {/* <p className="text-gray-500 text-base">
                            
                        </p> */}
          </div>
          <div className="mt-12 grid grid-cols-1 sm:grid-cols-2 gap-8 xl:mt-0">
            <div>
              <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">
                {t("Our services")}
              </h3>
              <ul className="mt-4 space-y-2">
                {services.map((service, key) => (
                  <li key={"footer-service-" + key}>
                    <Link
                      to={service.route}
                      className="text-base text-gray-500 hover:text-primary-900"
                    >
                      {service.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">
                {t("Contact us")}
              </h3>
              <div className="mt-4 space-y-4">
                {contacts.map((item, key) => (
                  <div
                    key={"footer-contact" + key}
                    className="flex items-start text-gray-500"
                  >
                    <item.icon className="flex-shrink-0 mr-1.5 h-5 w-5" />
                    <p className="ml-2 text-sm">{item.text}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8 text-sm text-gray-400">
          <div class="mb-6 sm:mb-0 sm:flex">
            <p>
              &copy; {new Date().getFullYear()} {app.shortName}.{" "}
              {t("All rights reserved")}.
            </p>
            <p class="sm:ml-4 sm:pl-4 sm:border-l sm:border-gray-200">
              {i18n.language === "en" ? (
                <button
                  class="hover:text-gray-900 ml-2"
                  onClick={() => {
                    i18n.changeLanguage("fr");
                  }}
                >
                  Français
                </button>
              ) : (
                <button
                  class="hover:text-gray-900"
                  onClick={() => {
                    i18n.changeLanguage("en");
                  }}
                >
                  English
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
