import { services as servicesData } from "../data";
import { CoverImg } from "../constants/images";
import { Link } from "react-router-dom";
import { Flocage } from ".";
import { useTranslation } from "react-i18next";

const Services = () => {
  const [t, i18n] = useTranslation();

  const services = servicesData.map((service) => {
    if (i18n.language === "fr") {
      return {
        title: service.title_fr,
        icon: service.icon,
        subtitle: service.subtitle_fr,
        description: service.description_fr,
        route: service.route,
      };
    }
    return service;
  });

  return (
    <>
      <div className="bg-white">
        <div className="relative pb-32 bg-gray-800">
          <div className="absolute inset-0">
            <img className="w-full h-full object-cover" src={CoverImg} alt="" />
            <div
              className="absolute inset-0 bg-gray-800 mix-blend-multiply"
              aria-hidden="true"
            />
          </div>
          <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-extrabold tracking-tight text-primary-900 md:text-4xl">
              {t("Our services")}
            </h2>
          </div>
        </div>
        <section
          className="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8"
          aria-labelledby="contact-heading"
        >
          <h2 className="sr-only" id="contact-heading">
            Nos Prestations
          </h2>
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 justify-items-center">
            {services.map((service, index) => (
              <div
                key={service.title + index}
                className="flex flex-col bg-white rounded-2xl shadow-xl text-center"
              >
                <div className="flex-1 relative py-8 px-6 md:px-8">
                  <service.icon className="mx-auto h-10 w-auto lg:h-12" />
                  <h3 className="mt-6 text-xl font-bold text-primary-900">
                    {service.title}
                  </h3>
                  <p className="mt-1 text-sm font-semibold text-gray-900">
                    {service.subtitle}
                  </p>
                  <p className="mt-4 text-sm text-gray-500">
                    {service.description}
                  </p>
                </div>
                <div className="p-4 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                  <Link
                    to={service.route}
                    className="text-sm font-medium text-primary-700 hover:text-primary-900"
                  >
                    {t("Learn more")}
                    <span aria-hidden="true"> &rarr;</span>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </section>
        <Flocage />
      </div>
    </>
  );
};

export default Services;
