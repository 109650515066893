import { app as appData } from "../data";
import { CoverImg } from "../constants/images";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const [t, i18n] = useTranslation();

  const app =
    i18n.language === "fr"
      ? { name: appData.name_fr, description: appData.description_fr }
      : appData;

  return (
    <div className="relative">
      <div className="relative overflow-hidden w-full h-96">
        <img
          className="w-full h-full object-center object-cover"
          src={CoverImg}
          alt=""
        />
      </div>
      <div className="absolute overflow-hidden top-0 inset-x-0 h-96  flex items-center justify-center bg-gray-900 bg-opacity-50 pt-16 pb-20 lg:py-48 lg:text-left">
        <div className="relative overflow-hidden px-4 text-center sm:px-16">
          <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
            {app.name}
          </h1>
          <p className="mt-3 sm:text-xl text-white line-clamp-2">
            {app.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
