const Input = ({ name, type, label, placeholder, required }) => {
  return (
    <div>
      <label htmlFor={name} className="sr-only">
        {label ? label : placeholder}
      </label>
      <input
        type={type}
        name={name}
        id={name}
        autoComplete={name}
        className="block w-full text-sm  py-2 px-3 placeholder-gray-400 focus:ring-primary-900 focus:border-primary-900 border-gray-200 rounded-md"
        placeholder={placeholder ? placeholder : label}
        required={required ? true : false}
      />
    </div>
  );
};

export default Input;
