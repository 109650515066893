import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { MenuIcon, XIcon, PhoneIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { ButtonLink } from "../components";
import { app as appData, services as servicesData } from "../data";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = () => {
  const [t, i18n] = useTranslation();

  const app =
    i18n.language === "fr"
      ? { name: appData.name_fr, logo: appData.logo }
      : appData;

  const services = servicesData.map((service) => {
    if (i18n.language === "fr") {
      return {
        slug: service.slug,
        route: service.route,
        icon: service.icon,
        title: service.title_fr,
        subtitle: service.subtitle_fr,
      };
    }
    return service;
  });

  return (
    <header>
      <Popover className="relative bg-white border-b border-gray-200">
        {({ close }) => (
          <>
            <div className="flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
              <div className="flex justify-start lg:w-0 lg:flex-1">
                <Link to="/">
                  <span className="sr-only">{app.name}</span>
                  <img
                    className="h-8 w-auto sm:h-10"
                    src={app.logo}
                    alt={app.name}
                  />
                </Link>
              </div>
              <div className="-mr-2 -my-2 md:hidden">
                <Popover.Button
                  id="menuBtn"
                  className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
                >
                  <span className="sr-only">{t("Open menu")}</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              <Popover.Group as="nav" className="hidden md:flex space-x-10">
                <Link
                  to="/"
                  className="text-base font-medium uppercase text-gray-900 hover:text-primary-900"
                >
                  {t("Home")}
                </Link>

                <Link
                  to="/about"
                  className="text-base font-medium uppercase text-gray-900 hover:text-primary-900"
                >
                  {t("Presentation")}
                </Link>

                <Popover className="relative">
                  {({ open, close }) => (
                    <>
                      <Popover.Button
                        className={classNames(
                          open ? "text-primary-900" : "text-gray-900",
                          "uppercase group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-primary-900 focus:outline-none"
                        )}
                      >
                        <span>{t("Our services")}</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? "text-gray-600" : "text-gray-400",
                            "ml-2 h-5 w-5 group-hover:text-gray-500"
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                              {services.map((service) => (
                                <Link
                                  key={"navService-" + service.slug}
                                  to={service.route}
                                  className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                                  onClick={close}
                                >
                                  <service.icon
                                    className="flex-shrink-0 h-6 w-6 text-primary-900"
                                    aria-hidden="true"
                                  />
                                  <div className="ml-4">
                                    <p className="text-base font-medium text-gray-900">
                                      {service.title}
                                    </p>
                                    <p className="mt-1 text-sm text-gray-500">
                                      {service.subtitle}
                                    </p>
                                  </div>
                                </Link>
                              ))}
                            </div>
                            <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                              <div className="flow-root">
                                <Link
                                  to="/contact"
                                  onClick={close}
                                  className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150"
                                >
                                  <PhoneIcon
                                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <span className="ml-3">
                                    {t("Contact us")}
                                  </span>
                                </Link>
                                {/* another link */}
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </Popover.Group>
              <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                <Link
                  to="/contact"
                  className="inline-flex items-center uppercase px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-900 hover:bg-primary-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-900"
                >
                  {t("Contact")}
                </Link>
              </div>
            </div>

            <Transition
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                  <div className="pt-5 pb-6 px-5">
                    <div className="flex items-center justify-between">
                      <div>
                        <Link to="/">
                          <img
                            className="h-8 w-auto"
                            src={app.logo}
                            alt={app.name}
                          />
                        </Link>
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
                          <span className="sr-only">{t("Close menu")}</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="mt-6">
                      <nav className="grid grid-cols-1 gap-7">
                        {services.map((service) => (
                          <Link
                            key={"mobileNavService-" + service.slug}
                            to={service.route}
                            className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                            onClick={close}
                          >
                            <service.icon className="h-6 w-6" />
                            <div className="ml-4 text-base font-medium text-gray-900">
                              {service.title}
                            </div>
                          </Link>
                        ))}
                      </nav>
                    </div>
                  </div>
                  <div className="py-6 px-5">
                    <div className="">
                      <ButtonLink
                        onClick={close}
                        text={t("Contact us")}
                        link="/contact"
                      />
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </header>
  );
};

export default Navbar;
